
$global-emphasis-color: #565656;
$global-border: #C6C6C6;

$base-h2-line-height: 1.1;
$base-margin-vertical: 10px;

$section-default-background: #565656;
$section-secondary-background: #BA1B1C;

$background-primary-background: #565656;
$background-secondary-background: #BA1B1C;

//$global-large-margin: 70px;

$global-font-family: 'Catamaran', sans-serif;
$base-heading-font-weight: 700;


$width-medium-width: 300px !default;

$container-xsmall-max-width: 600px;

//$base-h1-font-size-m
//$padding-padding-l: 60px;
  // Eigene Variablen

$breakpoint-small: 640px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;