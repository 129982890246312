/* catamaran-regular - latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  src: local('Catamaran Regular'), local('Catamaran-Regular'),
  url('fonts/catamaran-v5-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/catamaran-v5-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* catamaran-500 - latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 500;
  src: local('Catamaran Medium'), local('Catamaran-Medium'),
  url('fonts/catamaran-v5-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/catamaran-v5-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* catamaran-700 - latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 700;
  src: local('Catamaran Bold'), local('Catamaran-Bold'),
  url('fonts/catamaran-v5-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/catamaran-v5-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
