#masthead-logo img {
  /* image for masthead logo */
  max-width: 150px;
}

#edit-page {
  /* the edit page link that appears when page is editable */
  position: absolute;
  top: 10px;
  right: 10px;

}

#offcanvas-toggle {
  /* the hamburger icon that toggles the mobile navigation */
  //position: absolute;
  //top: 10px;
  //right: 15px;
  color: $section-secondary-background;
}

.debug {
  border: 1px solid green;
}

.gs-item {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: $global-xlarge-box-shadow;
}

.wrapper-for-ckeditor-field {
  h2 {
    margin-bottom: 10px !important;
    line-height: 1;

  }
  p {
    margin: initial !important;
  }
  &-dense {
    * {
      margin: 4px !important;
    }
    p {
      line-height: 1.2 !important;
    }
  }
  &-text-big {
    p {
      @extend .uk-text-large;
    }
  }
}

.wrapper-uk-list {
  ul {
    @extend .uk-list;
    @extend .uk-margin-remove;

  }
  &-bullet {
    ul {
      @extend .uk-list-bullet;
    }
  }
  &-divider {
    ul {
      @extend .uk-list-divider;
    }
  }
}

.wrapper-uk-list-bold {
  ul {
    @extend .uk-text-bold;
  }
}

.mod-light-extra {
  * {
    color: #ffffff !important;
    list-style: white !important;

  }
}

.wrapper-uk-list-margin-remove {
  li {
    margin-top: 0 !important;
  }
}

.gs-home-headline {
  margin-top: -40px;
}

.gs-news{
  border: 2px solid white;
}

.gs-home-batterie-left {
  width: 320px;
  @media (min-width: $breakpoint-medium) {
    border-top-left-radius: 90px;
    border-bottom-left-radius: 90px;
    &:before {
      content: "";
      width: 30px;
      height: 85px;
      border-radius: 9px;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      background: $background-primary-background;
      left: -15px;
    }
  }

}

.gs-home-batterie-right {
  width: 320px;
  @media (min-width: $breakpoint-medium) {
    border-bottom-right-radius: 90px;
    border-top-right-radius: 90px;
    &:after {
      content: "";
      width: 30px;
      height: 170px;
      border-radius: 9px;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      background: $background-primary-background;
      right: -8px;
    }
  }
}

.gs-home-batterie-inner {
  margin-left: 40px;
}

.gs-iframe-wrapper {
  overflow: hidden;
  position: relative;
}